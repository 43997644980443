import React, { useEffect, useState } from "react";
import axios from "../axiosInstance";
import { Table, Button, Form, Modal, NavItem } from "react-bootstrap";
import {
  FaRegPlusSquare,
  FaUserCircle,
  FaChevronCircleDown,
  FaTrashAlt,
  FaPen,
  FaFileExcel,
} from "react-icons/fa";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { VscCloudUpload, VscPulse } from "react-icons/vsc";

const ItemsManagement = () => {
  const [editModal, setEditModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [items, setItems] = useState([]);
  const [stocks, setStock] = useState([]);
  const [stockId, setstockId] = useState("");
  const [newItemsName, setnewItemsName] = useState("");
  const [newItemId, setNewItemId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [editItem, seteditItem] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchPurchases = async () => {
      const itemsRes = await axios.get("/api/deliveries");
      setItems(itemsRes.data);
    };
    fetchPurchases();
    axios
    .get("/api/stocks")
    .then((response) => setStock(response.data));
  }, []);
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);
  const fetchPurchases = async () => {
    try {
      const response = await axios.get("/api/deliveries");
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const handleUpdateStocks = async () => {
    try {
      const response = await axios.put(`/api/deliveries/${editItem.id}`, {
        qty: editItem.qty,
      });

      Swal.fire({
        icon: "success",
        title: "Updated",
        text: response.data.message,
      });

      setEditModal(false);
      fetchPurchases();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error updating the Item!",
      });
    }
  };
  const handleStocks = async () => {
    try {
      const response = await axios.put(`/api/stockdeliveries/${editItem.id}`, {
        stockId,
        req_unityPrice: editItem.req_unityPrice,
        newqty: editItem.newqty,
        item_id: editItem.item_id,
      });

      Swal.fire({
        icon: "success",
        title: "Updated",
        text: response.data.message,
      });

      setShowModal(false);
      fetchPurchases();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error updating Stocks!",
      });
    }
  };

  const handleSort = (field) => {
    setSortedField(field);
    const sortedData = [...items].sort((a, b) => {
      if (a[field] < b[field]) return -1;
      if (a[field] > b[field]) return 1;
      return 0;
    });
    setItems(sortedData);
  };

  const filteredItems = items.filter((item) =>
    item.item_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate pagination based on the filtered items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentStock = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  return (
    <div className="container mt-5 p-6">
      <br />
      {/* Search Input */}
      <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" style={{height: '50px'}}>
                      <i className="fas fa-search"></i> 
                    </span>
                  </div>
      <Form.Control
        type="text"
        placeholder="Search .........."
        className="mb-3"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      </div>
      </div>

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Purchase ID</th>
            <th onClick={() => handleSort("item_name")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Item Name</b>
                <FaChevronCircleDown />
              </div>
            </th>
            <th onClick={() => handleSort("min_stock_qty")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Unit Price</b>
                <FaChevronCircleDown />
              </div>
            </th>
            <th onClick={() => handleSort("min_stock_qty")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Purchased Quantity</b>
                <FaChevronCircleDown />
              </div>
            </th>
            <th onClick={() => handleSort("min_stock_qty")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Delived Quantity</b>
                <FaChevronCircleDown />
              </div>
            </th>
            <th onClick={() => handleSort("min_stock_qty")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Usable Quantity</b>
                <FaChevronCircleDown />
              </div>
            </th>
            <th onClick={() => handleSort("deleted")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Status</b>
                <FaChevronCircleDown />
              </div>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
  {currentStock.map((item) => (
    <tr key={item.purchase_id}>
      <td>{item.purchase_id}</td>
      <td>{item.item_name}</td>
      <td>{item.req_unityPrice}</td>
      <td>{item.req_qty}</td>
      <td>{item.delived_qty}</td>
      <td>{item.available_qty}</td>
      <td>
        {item.deleted === 1 ? (
          <span style={{ color: "red" }}>Inactive</span>
        ) : (
          <span style={{ color: "green" }}>Active</span>
        )}
      </td>
      <td className="d-flex justify-content-center gap-2">
        {/* Conditionally render the add button */}
        {item.available_qty > 0 ? (
          <Button
            variant="warning"
            onClick={() => {
              seteditItem(item);
              setShowModal(true);
            }}
          >
            <FaRegPlusSquare />
          </Button>
        ):(  <b></b>)}

        {/* Conditionally render and disable the edit button */}
        {item.req_qty !== item.delived_qty && (
          <Button
            variant="primary"
            onClick={() => {
              seteditItem(item);
              setEditModal(true);
            }}
          >
            <FaPen style={{ color: "#fff" }} />
          </Button>
        )}
      </td>
    </tr>
  ))}
</tbody>

      </Table>

      {/* Pagination */}
      <div className="d-flex gap-2 justify-content-end mt-3">
        {/* Previous Button */}
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>

        {/* First Page Button */}
        {totalPages > 3 && currentPage > 3 && (
          <Button
            onClick={() => setCurrentPage(1)}
            className={currentPage === 1 ? "active" : ""}
          >
            1
          </Button>
        )}

        {/* Ellipsis for pages between first page and current page */}
        {totalPages > 3 && currentPage > 4 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Current Page Button */}
        <Button onClick={() => setCurrentPage(currentPage)} className="active">
          {currentPage}
        </Button>

        {/* Ellipsis for pages between current page and last page */}
        {totalPages > 3 && currentPage < totalPages - 3 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Last Page Button */}
        {totalPages > 3 && currentPage < totalPages - 2 && (
          <Button
            onClick={() => setCurrentPage(totalPages)}
            className={currentPage === totalPages ? "active" : ""}
          >
            {totalPages}
          </Button>
        )}

        {/* Next Button */}
        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>

      {/* Edit Item Modal */}
      <Modal show={editModal} onHide={() => setEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delivery Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Item Name</Form.Label>
              <Form.Control
                type="text"
                value={editItem?.item_name || ""}
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Purchased Quantity</Form.Label>
              <Form.Control
                type="text"
                value={editItem?.req_qty || ""}
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Delived Quantity</Form.Label>
              <Form.Control
                type="text"
                value={editItem?.delived_qty || 0}
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Enter Delived Quantity</Form.Label>
              <Form.Control
                type="number"
                onChange={(e) =>
                  seteditItem({
                    ...editItem,
                    qty: e.target.value,
                  })
                }
                disabled={editItem?.delived_qty==editItem?.req_qty}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleUpdateStocks}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Record Stock Item Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Stock Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Item Name</Form.Label>
              <Form.Control
                type="text"
                value={editItem?.item_name || ""}
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3">
              
              <Form.Control
                type="hidden"
                value={editItem?.item_id || ""}
                readOnly
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Available Quantity</Form.Label>
              <Form.Control
                type="text"
                value={editItem?.delived_qty || 0}
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3">
              
              <Form.Control
                type="text"
                value={editItem?.req_unityPrice || 0}
                readOnly
              />
            </Form.Group>
            <Form.Group controlId="S">
            <Form.Label>Stock</Form.Label>
            <Form.Control
              as="select"
              value={stockId}
              onChange={(e) => setstockId(e.target.value)}
              required
            >
                <option value="">Select Stock</option>
                {stocks.map((stock) => (
                  <option key={stock.stock_id} value={stock.stock_id}>
                    {stock.stock_name}
                  </option>
                ))}
            </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Enter Quantity</Form.Label>
              <Form.Control
                type="number"
                onChange={(e) =>
                  seteditItem({
                    ...editItem,
                    newqty: e.target.value,
                  })
                } 
                max={editItem?.available_qty}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleStocks}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ItemsManagement;
