import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css"; // Assuming you want to add some custom styles
import { FaArrowUp } from "react-icons/fa";

const BackToTopButton = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This enables smooth scrolling
    });
  };

  return (
    <a
      className="btn btn-lg btn-primary btn-lg-square back-to-top"
      onClick={(e) => {
        e.preventDefault();
        scrollToTop();
      }}
    >
      <FaArrowUp />
    </a>
  );
};

export default BackToTopButton;
