import React, { useState, useEffect } from "react";
import axios from "../axiosInstance";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2"; // Import SweetAlert
import "sweetalert2/dist/sweetalert2.min.css"; // Import SweetAlert styles

const AssignMenuModal = ({ show, onHide, reloadMenuData }) => {
  const [users, setUsers] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [userMenuItems, setUserMenuItems] = useState([]);

  useEffect(() => {
    fetchUsers();
    fetchMenuItems();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      fetchUserMenuItems(selectedUser);
    }
  }, [selectedUser]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get("/api/users");
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchMenuItems = async () => {
    try {
      const response = await axios.get("/api/menuitems");
      setMenuItems(response.data);
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  const fetchUserMenuItems = async (userId) => {
    try {
      const response = await axios.get(
        `/api/usermenu/${userId}`
      );
      setUserMenuItems(response.data.map((item) => item.menu_id));
    } catch (error) {
      console.error("Error fetching user's menu items:", error);
    }
  };

  const handleCheckboxChange = (menuId) => {
    setUserMenuItems((prevState) =>
      prevState.includes(menuId)
        ? prevState.filter((id) => id !== menuId)
        : [...prevState, menuId]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        userId: selectedUser,
        menuIds: userMenuItems,
      };
      await axios.post("/api/assign", payload);
      onHide();
      reloadMenuData();
      Swal.fire({
        icon: "success",
        title: "Menu Assigned Successfully",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.error("Error assigning menu:", error);
      Swal.fire({
        icon: "error",
        title: "Assignment Failed",
        text: "Failed to assign menu to user.",
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Assign Menu to User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formUser">
            <Form.Label>User</Form.Label>
            <Form.Control
              as="select"
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              required
            >
              <option value="">Select User</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formMenu">
            <Form.Label>Menu Items</Form.Label>
            {menuItems.map((menuItem) => (
              <Form.Check
                key={menuItem.id}
                type="checkbox"
                label={menuItem.title}
                checked={userMenuItems.includes(menuItem.id)}
                onChange={() => handleCheckboxChange(menuItem.id)}
              />
            ))}
          </Form.Group>

          <Button variant="primary" type="submit">
            Update Assignment
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AssignMenuModal;
