import { Modal, Button, Form } from "react-bootstrap";
import axios from "../axiosInstance";
import Swal from "sweetalert2";

import "sweetalert2/dist/sweetalert2.min.css";
import React, { useState, useEffect } from "react";
const EditMenuItemModal = ({ show, onHide, menuItem, reloadMenuData }) => {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [icon, setIcon] = useState("");
  const [iconOptions, setIconOptions] = useState([]);
  const [parentId, setParentId] = useState(null);
  useEffect(() => {
    const fetchIcons = async () => {
      try {
        const response = await axios.get("/api/icons");
        setIconOptions(response.data.map((icon) => icon.icon_name));
      } catch (error) {
        console.error("Error fetching icons:", error);
      }
    };

    fetchIcons();
  }, []);
  useEffect(() => {
    if (menuItem) {
      setTitle(menuItem.title);
      setUrl(menuItem.url);
      setIcon(menuItem.icon);
      setParentId(menuItem.parent_id);
    }
  }, [menuItem]);
  useEffect(() => {
    fetchMenuItems();
  }, []);
  const fetchMenuItems = async () => {
    try {
      const response = await axios.get("/api/menuitems");
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`/api/menuitems/${menuItem.id}`, {
        title,
        url,
        icon,
        parentId,
      });
      onHide();
      reloadMenuData();
      Swal.fire({
        icon: "success",
        title: "Menu Item Updated",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        fetchMenuItems();
      });
    } catch (error) {
      console.error("Error updating menu item:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update menu item.",
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Menu Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formUrl">
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formIcon">
            <Form.Label>Icon</Form.Label>
            <Form.Control
              as="select"
              value={icon}
              onChange={(e) => setIcon(e.target.value)}
            >
              <option value="">Select an icon</option>
              {iconOptions.map((iconName, index) => (
                <option key={index} value={iconName}>
                  {iconName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <br />

          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditMenuItemModal;
