import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BiAlignRight } from "react-icons/bi";
import { FaChevronDown, FaSignOutAlt, FaUserCircle, FaUserCog } from "react-icons/fa";
import profile from "./images/p1.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Loader from "../Loader";
import axios from "../axiosInstance";
import Swal from "sweetalert2";
import "@fortawesome/fontawesome-free/css/all.min.css"; 

const Navbar = () => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [profileName, setProfileName] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);
      setProfileName(storedUser.name);
    } else {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const userRole = localStorage.getItem("role");
    setRole(userRole);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    setUser(null);
    navigate("/");
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Passwords do not match",
        text: "Please make sure your passwords match.",
      });
      return;
    }

    setLoading(true);

    try {
      await axios.post("/api/change-password", {
        email: user.email,
        currentPassword,
        newPassword,
      });

      Swal.fire({
        icon: "success",
        title: "Password Changed",
        text: "Your password has been successfully changed.",
      });

      setShowChangePasswordModal(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.error || "There was an error changing your password. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProfile = async () => {
    setLoading(true);

    try {
      await axios.post("/api/update-profile", { email: user.email, name: profileName });

      setUser({ ...user, name: profileName });

      Swal.fire({
        icon: "success",
        title: "Profile Updated",
        text: "Your profile has been successfully updated.",
      });

      setShowProfileModal(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.error || "There was an error updating your profile. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <nav className="navbar bg-light navbar-expand bg-red-200 navbar-light sticky-top px-4 py-0">
        <a href="Dashbord-admin" className="sidebar-toggler flex-shrink-0">
          <i>
            <BiAlignRight />
          </i>
        </a>
        <div className="navbar-nav align-items-center ms-auto">
          {user ? (
            <div className="nav-item">
              <a className="nav-link" data-bs-toggle="dropdown">
                <div className="d-flex justify-content-between gap-2 align-items-center mb-1">
                  <img
                    className="rounded-circle me-lg-2"
                    src={profile}
                    alt=""
                    style={{ width: "40px", height: "40px" }}
                  />
                  <span className="d-none d-lg-inline-flex">{user.name}-{role}</span>{" "}
                  <FaChevronDown />
                </div>
              </a>

              <div className="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
                <a
                  href="#"
                  className="dropdown-item"
                  onClick={() => setShowProfileModal(true)}
                >
                  <div className="d-flex justify-content-start gap-2 align-items-center mt-0">
                    <FaUserCircle /> <p className="mb-0">My Profile</p>
                  </div>
                </a>
                <a
                  href="#"
                  className="dropdown-item"
                  onClick={() => setShowChangePasswordModal(true)}
                >
                  <div className="d-flex justify-content-start gap-2 align-items-center mt-0">
                    <FaUserCog /> <p className="mb-0">Settings</p>
                  </div>
                </a>
                <a href="#" className="dropdown-item" onClick={handleLogout}>
                  <div className="d-flex justify-content-start gap-2 align-items-center mt-0">
                    <FaSignOutAlt /> <p className="mb-0">LogOut</p>
                  </div>
                </a>
              </div>
            </div>
          ) : (
            <a href="/" className="nav-item nav-link">
              Login
            </a>
          )}
        </div>
      </nav>

      {/* Modal for Change Password */}
      <Modal
        show={showChangePasswordModal}
        onHide={() => setShowChangePasswordModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-lock"></i> 
                    </span>
                  </div>
            
            <input
              type="password"
              className="form-control"
              id="currentPassword"
              placeholder="Enter current password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
          </div>
          </div>
          <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-lock"></i> 
                    </span>
                  </div>
            <input
              type="password"
              className="form-control"
              id="newPassword"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          </div>
          <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-lock"></i> 
                    </span>
                  </div>
            
            <input
              type="password"
              className="form-control"
              id="confirmPassword"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowChangePasswordModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleChangePassword}
            disabled={loading}
          >
            {loading ? <Loader size={20} color="#fff" /> : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Profile Update */}
      <Modal
        show={showProfileModal}
        onHide={() => setShowProfileModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-user"></i> 
                    </span>
                  </div>
            <input
              type="text"
              className="form-control"
              id="profileName"
              placeholder="Enter your name"
              value={profileName}
              onChange={(e) => setProfileName(e.target.value)}
              required
            />
          </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowProfileModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleUpdateProfile}
            disabled={loading}
          >
            {loading ? <Loader size={20} color="#fff" /> : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Navbar;
