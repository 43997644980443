import React from "react";
import "./Loader.css"; // Include the CSS for the loader

const Loader = ({ size = 40, color = "#007bff" }) => {
  return (
    <div
      className="loader"
      style={{
        width: size,
        height: size,
        border: `4px solid ${color}`,
        borderTop: `4px solid transparent`,
      }}
    ></div>
  );
};

export default Loader;
