import React, { useEffect, useState } from "react";
import axios from "../axiosInstance";
import { MdOutlineDashboard } from "react-icons/md";
import {
  FaBoxTissue,
  FaChevronDown,
  FaList,
  FaRegDizzy,
  FaProductHunt,
  FaAward,
  FaHistory,
  FaCogs,
} from "react-icons/fa";
import {
  VscGift,
  VscWhitespace,
  VscSymbolBoolean,
  VscReferences,
} from "react-icons/vsc";
import {
  AiFillHome,
  AiOutlineSetting,
  AiOutlineSearch,
  AiFillMessage,
  AiFillSetting,
} from "react-icons/ai";
import {
  PiUsersThree,
  PiHeartFill,
  PiUserCircle,
  PiBagFill,
  PiGraphFill,
  PiCalendarFill,
} from "react-icons/pi";
import { BiCar, BiUser, BiWorld } from "react-icons/bi";
import { GiHamburgerMenu, GiSettingsKnobs } from "react-icons/gi";
import { ImProfile, ImUsers } from "react-icons/im";
import { IoMdStats } from "react-icons/io";
import "./NotFound.css";

const iconMapping = {
  MdOutlineDashboard: MdOutlineDashboard,
  FaBoxTissue: FaBoxTissue,
  FaChevronDown: FaChevronDown,
  FaList: FaList,
  FaRegDizzy: FaRegDizzy,
  FaProductHunt: FaProductHunt,
  FaAward: FaAward,
  FaHistory: FaHistory,
  FaCogs: FaCogs,
  VscGift: VscGift,
  VscWhitespace: VscWhitespace,
  VscSymbolBoolean: VscSymbolBoolean,
  VscReferences: VscReferences,
  AiFillHome: AiFillHome,
  AiOutlineSetting: AiOutlineSetting,
  AiOutlineSearch: AiOutlineSearch,
  AiFillMessage: AiFillMessage,
  AiFillSetting: AiFillSetting,
  BiCar: BiCar,
  BiUser: BiUser,
  BiWorld: BiWorld,
  GiHamburgerMenu: GiHamburgerMenu,
  GiSettingsKnobs: GiSettingsKnobs,
  ImProfile: ImProfile,
  ImUsers: ImUsers,
  IoMdStats: IoMdStats,
  PiUsersThree: PiUsersThree,
  PiHeartFill: PiHeartFill,
  PiUserCircle: PiUserCircle,
  PiBagFill: PiBagFill,
  PiGraphFill: PiGraphFill,
  PiCalendarFill: PiCalendarFill,
};

const Sidebar = ({ userId }) => {
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await axios.get(`/api/menu/${userId}`);
        setMenuData(response.data);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    if (userId) {
      fetchMenuData();
    }
  }, [userId]);

  const renderIcon = (iconName) => {
    const IconComponent = iconMapping[iconName];
    return IconComponent ? <IconComponent className="me-3" /> : null;
  };

  const MenuItem = ({ item }) => {
    return (
      <div>
        {item.subMenu.length > 0 ? (
          <div className="nav-item justify-content-between">
            <a href={item.url} className="nav-link" data-bs-toggle="dropdown">
              <i className=""><b>{renderIcon(item.icon)}</b></i> {item.title}
            </a>
            <div className="dropdown-menu bg-transparent  border-0">
              {item.subMenu.map((subItem) => (
                <a
                  href={subItem.url}
                  className="dropdown-item  d-flex justify-content-start gap-2"
                  key={subItem.id}
                >
                  {subItem.icon && (
                    <i className="">{renderIcon(subItem.icon)}</i>
                  )}
                  {subItem.title}
                </a>
              ))}
            </div>
          </div>
        ) : (
          <a href={item.url} className="nav-item nav-link ">
            <i className="">{renderIcon(item.icon)}</i>
            {item.title}
          </a>
        )}
      </div>
    );
  };

  return (
    <div className="sidebar pe-4 pb-3">
      <nav className="navbar navbar-light">
        <a href="Dashbord-admin" className="navbar-brand mx-4 mb-3 w-90">
          <h3 className="text-primary">NICE BMC</h3>
        </a>
        <div className="navbar-nav w-90 flex-column justify-content-between">
          {menuData.map((menuItem) => (
            <MenuItem key={menuItem.id} item={menuItem} />
          ))}
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
