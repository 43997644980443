import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../axiosInstance";
import Swal from "sweetalert2"; 
import "bootstrap/dist/css/bootstrap.min.css";
import "./LoginPage.css";
import Loader from "../Loader";
import { Button, Modal, Form } from "react-bootstrap";
import "@fortawesome/fontawesome-free/css/all.min.css"; 
import logo from "../assets/logo.png";
const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [forgotLoading, setForgotLoading] = useState(false); // Add loading state for forgot password
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post("/api/usersloginpage", { email, password });
      const { user } = response.data;
      const { id, name } = user;

      localStorage.setItem("token", response.data.token);
      localStorage.setItem("role", response.data.user.role);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("userId", id);

      Swal.fire({
        icon: "success",
        title: "Login Successful",
        text: `Welcome back, ${name}!!!`,
        timer: 3000,
        timerProgressBar: true,
        willClose: () => {
          navigate("/Dashbord-admin");
        },
      });
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        setError("Invalid email or password");
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: "Invalid email or password",
        });
      } else {
        setError("An error occurred. Please try again later.");
        Swal.fire({
          icon: "error",
          title: "An Error Occurred",
          text: "Please try again later.",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!forgotEmail) {
      Swal.fire({
        icon: "warning",
        title: "Please enter your email",
      });
      return;
    }

    setForgotLoading(true); // Set loading to true before sending request

    try {
      const response = await axios.post("/api/forgot-password", { email: forgotEmail });

      Swal.fire({
        icon: "success",
        title: "Email Sent",
        text: "A new password has been sent to your email address.",
      });

      setShowForgotPasswordModal(false); // Close the modal after successful submission
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Unable to send reset email. Please try again.",
      });
    } finally {
      setForgotLoading(false); // Set loading to false after request is complete
    }
  };

  return (
    <>
      <div className="login-container d-flex justify-content-center align-items-center">
        <div className="login-card p-4 shadow-lg d-flex flex-column">
            {/* <center><h4 className="mb-3 ">NICE BMC</h4></center> */}
            <div className="logo">
              <img src={logo} alt="Logo" className="logo" />
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
            {loading ? (
              <div className="d-flex justify-content-center">
                <Loader size={60} color="#3498db" />
              </div>
            ) : (
             
              <form onSubmit={handleLogin}> 
              
              <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-envelope"></i> {/* Email icon */}
                    </span>
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-lock"></i> 
                    </span>
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-lg w-100 mb-3">
               Log in
              </button>

                <Button
                  variant="link"
                  className="btn-link"
                  onClick={() => setShowForgotPasswordModal(true)}
                >
                  Forgot Password?
                </Button>
              </form>
            )}
          </div>
        </div>
      

      {/* Modal for Forgot Password */}
      <Modal
        show={showForgotPasswordModal}
        onHide={() => setShowForgotPasswordModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-envelope"></i> 
                    </span>
                  </div>
            <input
              type="email"
              className="form-control"
              id="forgotEmail"
              placeholder="Enter email"
              value={forgotEmail}
              onChange={(e) => setForgotEmail(e.target.value)}
              required
            />
          </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowForgotPasswordModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleForgotPassword}
            disabled={forgotLoading} // Disable button during loading
          >
            {forgotLoading ? (
              <Loader size={20} color="#fff" /> // Show loader inside button
            ) : (
              "Send New Password"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoginPage;
