// NotFound.jsx
import React, { useEffect } from "react";
import Swal from "sweetalert2";
import "./NotFound.css";

const NotFound = () => {
  useEffect(() => {
    Swal.fire({
      title: "404 - Page Not Found",
      text: "The page you are looking for does not exist.",
      icon: "error",
      confirmButtonText: "Go Back",
      onClose: () => {
        console.log("User clicked 'Go Back' button.");
      },
    });
  }, []);

  return (
    <div className="not-found-container">
      <h2>404 - Page Not Found</h2>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
