import React, { useState, useEffect } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import EditSubmenuModal from "./EditSubmenuModal"; 
import Swal from "sweetalert2";
import axios from "../axiosInstance"; 

const ViewSubMenuModal = ({
  show,
  onHide,
  subMenuItems,
  handleEdit,
  // Add a reloadSubmenuData function to trigger a data reload
  reloadSubmenuData,
}) => {
  const [editModalShow, setEditModalShow] = useState(false);
  const [selectedSubmenuItem, setSelectedSubmenuItem] = useState(null);

  const handleEditModalOpen = (subMenuItem) => {
    setSelectedSubmenuItem(subMenuItem);
    setEditModalShow(true);
  };
  const fetchMenuItems = async () => {
    try {
      const response = await axios.get("/api/menuitems");
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };
  useEffect(() => {
    fetchMenuItems();
  }, []);
  const handleEditModalClose = () => {
    setEditModalShow(false);
    setSelectedSubmenuItem(null);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Perform the deletion request
          await axios.delete(`/api/submenuitem/${id}`);
  
          // Show success message and close modal after deletion
          Swal.fire({
            title: 'Deleted!',
            text: 'The submenu item has been deleted.',
            icon: 'success',
            timer: 2000, // Show for 2 seconds
            timerProgressBar: true,
            didClose: () => {
              onHide();
            }
          });
        } catch (error) {
          console.error("Error deleting submenu item:", error);
        }
      }
    });
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Submenus</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover className="mt-3 w-100">
            <thead>
              <tr>
                <th>Title</th>
                <th>URL</th>
                <th>Icon</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {subMenuItems.map((subMenuItem) => (
                <tr key={subMenuItem.id}>
                  <td>{subMenuItem.title}</td>
                  <td>{subMenuItem.url}</td>
                  <td>{subMenuItem.icon}</td>
                  <td>
                    <Button
                      variant="warning"
                      onClick={() => handleEditModalOpen(subMenuItem)}
                      className="ms-2 text-white"
                    >
                      <FaEdit />
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(subMenuItem.id)}
                      className="ms-2 text-white"
                    >
                      <FaTrashAlt />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <EditSubmenuModal
        show={editModalShow}
        onHide={handleEditModalClose}
        submenuItem={selectedSubmenuItem}
        reloadSubmenuData={reloadSubmenuData} // Pass the reload function to the edit modal
      />
    </>
  );
};

export default ViewSubMenuModal;
