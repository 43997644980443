import React from "react";
import Sidebar from "./components/Sidebar";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./components/all.css";
import Navbar from "./components/Navbar";
import Dashboard from "./components/Dashboard";
import Footer from "./components/Footer";
import "./components/css/style.css";
import "./components/css/bootstrap.min.css";
import "./components/js/mains";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import BackToTopButton from "./BackToTopButton";
import "./index.css";
function App() {
  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar userId={localStorage.getItem("userId")} />{" "}
      <div className="content">
        <Navbar />
        <Dashboard />
        <Footer />
      </div>
      <BackToTopButton />
    </div>
  );
}

export default App;
