import React from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../components/all.css";
import "../components/css/style.css";
import "../components/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import RoleManagement from '../pages/RolesManagement'

function Roles() {
  return (
    <div className="d-flex vh-100 w-100">
      <Sidebar userId={localStorage.getItem("userId")} />
      <div className="content flex-grow-1 d-flex flex-column">
        <Navbar />
        <div className="flex-grow-1 mt-1 p-1">
       <RoleManagement/>
      </div>
    </div>
    </div>
  );
}

export default Roles;
