import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "../axiosInstance";
import Swal from "sweetalert2"; // Import SweetAlert

import "sweetalert2/dist/sweetalert2.min.css"; // Import SweetAlert styles

const EditSubmenuModal = ({ show, onHide, submenuItem, reloadSubmenuData }) => {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [icon, setIcon] = useState("");
  const [menuId, setMenuId] = useState(null);
  useEffect(() => {
    fetchMenuItems();
  }, []);
  const fetchMenuItems = async () => {
    try {
      const response = await axios.get("/api/menuitems");
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };
  useEffect(() => {
    if (submenuItem) {
      setTitle(submenuItem.title || ""); // Ensure title is initialized safely
      setUrl(submenuItem.url || ""); // Ensure url is initialized safely
      setIcon(submenuItem.icon || ""); // Ensure icon is initialized safely
      setMenuId(submenuItem.menu_id || null); // Ensure menu_id is initialized safely
    }
  }, [submenuItem]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `/api/submenuitems/${submenuItem.id}`,
        {
          title,
          url,
          icon,
          menuId,
        }
      );
      onHide();
      fetchMenuItems();
      reloadSubmenuData();
      Swal.fire({
        icon: "success",
        title: "Submenu Item Updated",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error("Error updating submenu item:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update submenu item.",
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Submenu Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formUrl">
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formIcon">
            <Form.Label>Icon</Form.Label>
            <Form.Control
              type="text"
              value={icon}
              onChange={(e) => setIcon(e.target.value)}
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditSubmenuModal;
