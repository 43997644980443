import React, { useEffect, useState } from "react";
import axios from "../axiosInstance";
import { Table, Button, Form, Modal } from "react-bootstrap";
import {
  FaRegPlusSquare,
  FaUserCircle,FaEye,
} from "react-icons/fa";
import Swal from "sweetalert2";
import Select from "react-select";
import debounce from "lodash.debounce";
import { VscCloudUpload, VscTrash,} from "react-icons/vsc";
import { BsArrowDownUp } from "react-icons/bs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import imgHeader from "../assets/header.jpg"
import imgFooter from "../assets/footer.jpg"
const StocksManagement = () => {
    const [user, setUser] = useState(null);
    useEffect(() => {
      const storedUser = JSON.parse(localStorage.getItem("user"));
      if (storedUser) {
        setUser(storedUser);
      }
    }, []);
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [locations, setLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [editLocation, setEditLocation] = useState(null);
  const [file, setFile] = useState(null);
  const [comments, setComments] = useState("");
  const [customers, setCustomers] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [formError, setFormError] = useState("");
  const [items, setItems] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  const [orderdate, setOrderdate]= useState("");
  const [orders, setorders]= useState([]);
  const [showPayNowModal, setShowPayNowModal] = useState(false);
  const [amountpaid, setAmountPaid] = useState('');
  const [pay_mode, setPayMode] = useState('');
  const [pay_date, setPayDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showPaymentHistoryModal, setShowPaymentHistoryModal] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");

  const handleClosePaymentModal = () => setShowPaymentModal(false);
  const handlePaymentAmountChange = (e) => {
    setPaymentAmount(e.target.value);
  };
  const handleRecordPayment = (order_id) => {
    if (!paymentMethod || !paymentAmount || !selectedOrder) {
      Swal.fire("Error", "Please fill out all fields", "error");
      return;
    }

    const paymentData = {
      order_id: selectedOrder.order.order_id, // Correct key for the backend
      method: paymentMethod,
      amount: parseFloat(paymentAmount),
    };

    // Display the data being sent to the backend
    Swal.fire({
      title: "Data to be sending........",
      icon: "info",
      showCancelButton: true, // Allow the user to cancel the request
      confirmButtonText: "Send Payment",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post("/api/order-payments", paymentData)
          .then(() => {
            Swal.fire("Success", "Payment recorded successfully", "success");
            handleClosePaymentModal();
            handleCloseDetailModal();
            axios.get("/api/orders").then((response) => setorders(response.data));
            fetchLocations();
          })
          .catch((error) => {
            Swal.fire(
              "Error",
              "There was a problem recording the payment",
              "error"
            );
            console.error("Error recording payment:", error);
          });
      }
    });
  };
  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };
  useEffect(() => {
    axios.get("/api/itemsinstocks").then((response) => setItems(response.data));
    axios
      .get("/api/accounts")
      .then((response) => setAccounts(response.data))
      .catch((error) => console.error("Error fetching accounts:", error));
      const fetchLocations = async () => {
        const locationsRes = await axios.get("/api/orders");
        setLocations(locationsRes.data);
      };
  
      fetchLocations();
  }, []);
   const fetchLocations = async () => {
    try {
      const response = await axios.get("/api/orders");
      setLocations(response.data);
    } catch (error) {
      console.error("Error fetching order:", error);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!selectedCustomer || selectedCustomer.value === "unknown") {
      setFormError("Please select a customer or confirm 'Unknown Customer'.");
      Swal.fire("Error", "You must select a customer.", "error");
      return;
    }
    setFormError("");
  
    // Show SweetAlert confirmation dialog
    Swal.fire({
      title: 'Confirm order',
      text: "Do you want to pay now or later?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Pay Now',
      cancelButtonText: 'Later',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked "Pay Now"
        setShowPayNowModal(true);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        
        const orderData = {
          user_order_by: user.id,
          customer_id: selectedCustomer.value,
          orderdate,
          items: selectedItems,
          comments,
          amountpaid: 0,
          pay_mode: '',  
          pay_date: ''    
        };
  
        axios
          .post("/api/create-order-items", orderData)
          .then(() => {
            Swal.fire("Success!", "Order created successfully!", "success");
            axios.get("/api/orders").then((response) => setorders(response.data));
            setShowModal(false);
            fetchLocations();
          })
          .catch((error) => {
            const errorMessage = error.response?.data?.message || "There was an error creating the order.";
            Swal.fire("Error!", errorMessage, "error");
            console.error("Error creating order:", error);
          });
      }
    });
  };
  
  const handlePayNowSubmit = (e) => {
    e.preventDefault();
    
    const paymentData = {
      amountpaid,
      pay_mode,
      pay_date,
    };
  
    const orderData = {
      user_order_by: user.id,
      customer_id: selectedCustomer.value,
      orderdate,
      items: selectedItems,
      comments,
      ...paymentData, // Add payment data
    };
  
    axios
      .post("/api/create-order-items", orderData)
      .then(() => {
        Swal.fire("Success!", "Order created successfully!", "success");
        axios.get("/api/orders").then((response) => setorders(response.data));
        setShowPayNowModal(false);
        setShowModal(false);
        fetchLocations();
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || "There was an error creating the order.";
        Swal.fire("Error!", errorMessage, "error");
        console.error("Error creating order:", error);
      });
  };
  const handleDeleteOrder = (order_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This order will be permanently deleted.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/api/delete-order/${order_id}`)
          .then(() => {
            Swal.fire({
              title: "Deleted!",
              text: "The order has been deleted.",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
            // Refresh the order list after deletion
            setLocations(locations.filter((location) => location.order_id !== order_id));
          })
          .catch((error) => {
            Swal.fire(
              "Error",
              "There was a problem deleting the order.",
              "error"
            );
            console.error("Error deleting order:", error);
          });
      }
    });
  };
  const handleUpdate = async () => {
    try {
      const response = await axios.put(`/api/stocks/${editLocation.stock_id}`, {
        stock_name: editLocation.stock_name,
        deleted: editLocation.deleted,
      });

      Swal.fire({
        icon: "success",
        title: "Updated",
        text: response.data.message,
      });

      setEditModal(false);
      fetchLocations();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error updating the stock location!",
      });
    }
  };
  const handleShowDetailModal = (order_id) => {
    axios
      .get(`/api/orders/${order_id}`)
      .then((response) => {
        setSelectedOrder(response.data);
        setShowDetailModal(true);
      })
      .catch((error) =>
        console.error("Error fetching Order details:", error)
      );
  };
  const handleCloseDetailModal = () => setShowDetailModal(false);
  const handleShowPaymentHistoryModal = (orderId) => {
    axios
      .get(`/api/order/${orderId}/payments`)
      .then((response) => {
        setPaymentHistory(response.data);
        setShowPaymentHistoryModal(true);
      })
      .catch((error) =>
        console.error("Error fetching payment history:", error)
      );
  };
  const handleShowPaymentModal = () => setShowPaymentModal(true);
  const handleSort = (field) => {
    setSortedField(field);
    const sortedData = [...locations].sort((a, b) => {
      if (a[field] < b[field]) return -1;
      if (a[field] > b[field]) return 1;
      return 0;
    });
    setLocations(sortedData);
  };
  const handleClosePaymentHistoryModal = () => {
    setShowPaymentHistoryModal(false);
    setPaymentHistory([]);
  };
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handleUpload = async () => {
    if (!file) {
      Swal.fire({
        icon: "warning",
        title: "No file selected",
        text: "Please select an Excel file to upload.",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("/api/orders/import", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
      fetchLocations();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error uploading the file!",
      });
    }
  };
  const handleAddItem = () => {
    setSelectedItems([
      ...selectedItems,
      { item_id: "", req_qty: 1, req_unitprice: 0 },
    ]);
  };
  
  const handleItemChange = (index, key, value) => {
    const newSelectedItems = [...selectedItems];
    newSelectedItems[index][key] = value;
  
    if (key === "item_id") {
      const selectedItem = items.find((item) => item.id === parseInt(value));
      if (selectedItem) {
        newSelectedItems[index].item_id = selectedItem.id;
        newSelectedItems[index].name = selectedItem.name;
      }
    }
  
    setSelectedItems(newSelectedItems);
  };
  
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      width: '100%',
      zIndex: 9999, 
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '300px',
      overflowY: 'auto', 
    }),
    singleValue: (provided) => ({
      ...provided,
      maxWidth: '300px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
  };
  const handleItemSelect = (selectedOption, index) => {
    // Handle both selected and new item cases
    if (selectedOption) {
      handleItemChange(index, "item_id", selectedOption.value);
    } else {
      handleItemChange(index, "item_id", '');
    }
  };
  
  // Convert items to the format expected by react-select
  const itemOptions = items.map((it) => ({
    value: it.item_id,
    label: it.item_name,
  }));

  const handleRemoveItem = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This item will be removed from the list.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const newSelectedItems = selectedItems.filter((_, i) => i !== index);
        setSelectedItems(newSelectedItems);
        Swal.fire({
          title: "Removed!",
          text: "The item has been removed.",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  const defaultCustomer = { value: "unknown", label: "Unknown Customer" };

  // Fetch all customers when input is focused
  const fetchAllCustomers = () => {
    axios
      .get("/api/customers")
      .then((response) => {
        const customerOptions = Array.isArray(response.data)
          ? response.data.map((m) => ({
              value: m.customer_id,
              label: m.names,
            }))
          : []; // Ensure it's an array
        setCustomers(customerOptions);
      })
      .catch((error) => console.error("Error fetching customers:", error));
  };

  // Debounced search functionality
  const fetchCustomers = debounce((names) => {
    axios
      .get("/api/customers/search", { params: { query: names } })
      .then((response) => {
        const customerOptions = response.data.map((c) => ({
          value: c.customer_id,
          label: c.names,
        }));
        setCustomers([defaultCustomer, ...customerOptions]);
      })
      .catch((error) => console.error("Error fetching customers:", error));
  }, 300);
  const handleDownload = (order) => {
    const doc = new jsPDF();
    doc.addImage(imgHeader, 'JPEG', 10, 10, 190, 20); // adjust position and size
  
    // Add Order Information
    doc.setFontSize(12);
    doc.text(`Order Number: ${order.order.order_id}`, 10, 40);
    doc.text(`Ordered By: ${order.order.name}`, 10, 50);
    doc.text(`Customer Name: ${order.order.names}`, 10, 60);
    doc.text(
      `Date: ${new Date(order.order.order_date).toLocaleDateString()}`,
      10,
      70
    );
  
    // Generate table for order details
    const orderItems = order.details.map((detail) => [
      detail.item_name,
      detail.total_quantity_per_item,
      detail.unitprice.toFixed(2),
      (detail.total_quantity_per_item * detail.unitprice).toFixed(2),
    ]);
    let yOffset = 100;
    doc.text("Item Details", 10, yOffset);
    yOffset += 10;
    doc.autoTable({
      startY: yOffset,
      head: [["Item Name", "Quantity", "Unit Price", "Total"]],
      body: orderItems,
    });
  
    // Add Total, Paid, and Remaining Amounts
    const totalAmount = order.details.reduce(
      (total, detail) =>
        total + detail.total_quantity_per_item * detail.unitprice,
      0
    );
    const paidAmount = order.paymentDetails.total_paid;
    const remainingAmount = totalAmount - paidAmount;
  
    doc.text(
      `Total Amount: ${totalAmount.toFixed(2)} RWF`,
      10,
      doc.lastAutoTable.finalY + 10
    );
    doc.text(
      `Paid Amount: ${paidAmount.toFixed(2)} RWF`,
      10,
      doc.lastAutoTable.finalY + 20
    );
    doc.text(
      `Remaining Amount: ${remainingAmount.toFixed(2)} RWF`,
      10,
      doc.lastAutoTable.finalY + 30
    );
  
    // Add a footer image
    doc.addImage(imgFooter, 'JPEG', 10, 250, 190, 20); // adjust position and size for footer
  
    // Save the PDF
    doc.save(`Order_${order.order.order_id}.pdf`);
  };
  const showAllOrders = () => setFilterStatus("all");
const showCompleteOrders = () => setFilterStatus("complete");
const showInProgressOrders = () => setFilterStatus("inProgress");
  // Calculate indices for pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Filter locations based on filterStatus and search term
  const filteredLocations = locations
    .filter(location => {
      // Filter by payment status
      if (filterStatus === "complete") {
        return location.amount_paid >= location.sold_cost; // Fully paid
      } else if (filterStatus === "inProgress") {
        return location.amount_paid < location.sold_cost; // Not fully paid
      } else {
        return true; // Show all
      }
    })
    .filter(location => {
      // Filter by search term
      return (
        (location.names && location.names.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (location.name && location.name.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (location.order_date && new Date(location.order_date).toLocaleDateString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (location.payment_mode && location.payment_mode.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (location.amount_paid && location.amount_paid.toString().includes(searchTerm))
      );
    });

  // Paginate the filtered results
  const currentLocations = filteredLocations.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // Calculate total number of pages
  const totalPages = Math.ceil(filteredLocations.length / itemsPerPage);

  return (
    <div className="container mt-1">
      <br />
      <div className="d-flex justify-content-between gap-2 align-items-center mb-3">
        <Button
          variant="success"
          className="ms-2"
          onClick={() => setShowModal(true)}
        >
          <div className="d-flex justify-content-between gap-2 align-items-center mb-1">
            <FaRegPlusSquare />
            <b>Add New Order</b>
            <FaUserCircle />
          </div>
        </Button>
        <div>
          <Form className="d-flex gap-2">
            <Form.Group className="mb-3">
              <Form.Label>
                <b>Upload Excel File</b>
              </Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
            <Button
              className="mt-4 align-items-center"
              variant="primary"
              onClick={handleUpload}
              style={{
                height: "45px",
                width: "40px",
                padding: 0,
              }}
            ><VscCloudUpload  className="align-items-center"  style={{
                height: "30px",
                width: "30px",
                padding: "2px",
                marginRight: "20px",

              }}/>
            </Button>
          </Form>
        </div>
      </div>
      <div className="mb-3">
      <button className="btn btn-primary me-2" onClick={showAllOrders}>All</button>
      <button className="btn btn-success me-2" onClick={showCompleteOrders}>Complete</button>
      <button className="btn btn-warning" onClick={showInProgressOrders}>In Progress</button>
    </div>
      {/* Search Input */}
      <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" style={{height: '50px'}}>
                      <i className="fas fa-search"></i> 
                    </span>
                  </div>
      <Form.Control
        type="text"
        placeholder="Search .........."
        className="mb-3"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      </div>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => handleSort("order_date")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Order Date</b>
                <BsArrowDownUp style={{ color: '#33a8ff', cursor: 'pointer' }} />
              </div>
            </th>
            <th onClick={() => handleSort("name")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Order By</b>
                <BsArrowDownUp style={{ color: '#33a8ff', cursor: 'pointer' }} />
              </div>
            </th>
            <th onClick={() => handleSort("names")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Customer Name</b>
                <BsArrowDownUp style={{ color: '#33a8ff', cursor: 'pointer' }} />
              </div>
            </th>
            <th onClick={() => handleSort("pay_mode")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Payment Method</b>
                <BsArrowDownUp style={{ color: '#33a8ff', cursor: 'pointer' }} />
              </div>
            </th>
            <th onClick={() => handleSort("amountPaid")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Amount Paid</b>
                <BsArrowDownUp style={{ color: '#33a8ff', cursor: 'pointer' }} />
              </div>
            </th>
            <th onClick={() => handleSort("amountPaid")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Amount Due</b>
                <BsArrowDownUp style={{ color: '#33a8ff', cursor: 'pointer' }} />
              </div>
            </th>
            <th onClick={() => handleSort("pay_date")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Payment Date</b>
                <BsArrowDownUp style={{ color: '#33a8ff', cursor: 'pointer' }} />
              </div>
            </th>
            
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
  {filteredLocations.map((location) => (
    <tr key={location.order_id}>
      <td>{new Date(location.order_date).toLocaleDateString()}</td>
      <td>{location.name}</td>
      <td>{location.names}</td>
      <td>{location.payment_mode}</td>
      <td style={{ color: '#28a745', fontWeight: 800 }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'RWF' }).format(location.amount_paid)}</td>
      <td style={{ color: '#28a745', fontWeight: 800 }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'RWF' }).format(location.sold_cost)}</td>
      <td>{location.pay_date}</td>

      <td className="d-flex justify-content-center gap-2">
        <Button
          variant="success"
          onClick={() => handleShowDetailModal(location.order_id)}
        >
          <FaEye style={{ color: 'white', fontSize: '19px', cursor: 'pointer' }} />
        </Button>

        <Button
          variant="danger"
          onClick={() => handleDeleteOrder(location.order_id)}
        >
          <VscTrash style={{ color: 'white', fontSize: '19px', cursor: 'pointer' }} />
        </Button>
      </td>
    </tr>
  ))}

<tr>
  <td colSpan="4">Total</td>
  <td style={{ color: '#28a745', fontWeight: 800, fontSize: '20px' }}>
    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'RWF' }).format(
      currentLocations.reduce((total, location) => total + parseFloat(location.amount_paid || 0), 0)
    )}
  </td>
  <td style={{ color: '#28a745', fontWeight: 800, fontSize: '20px' }}>
    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'RWF' }).format(
      currentLocations.reduce((total, location) => total + parseFloat(location.sold_cost || 0), 0)
    )}
  </td>
  <td colSpan="2" style={{ color: 'red', fontWeight: 800, fontSize: '20px' }}>
    Rem: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'RWF' }).format(
      currentLocations.reduce(
        (total, location) => total + (parseFloat(location.sold_cost || 0) - parseFloat(location.amount_paid || 0)),
        0
      )
    )}
  </td>
</tr>

</tbody>


      </Table>

      {/* Pagination */}
      <div className="d-flex gap-2 justify-content-end mt-3">
        {/* Previous Button */}
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>

        {/* First Page Button */}
        {totalPages > 3 && currentPage > 3 && (
          <Button
            onClick={() => setCurrentPage(1)}
            className={currentPage === 1 ? "active" : ""}
          >
            1
          </Button>
        )}

        {/* Ellipsis for pages between first page and current page */}
        {totalPages > 3 && currentPage > 4 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Current Page Button */}
        <Button onClick={() => setCurrentPage(currentPage)} className="active">
          {currentPage}
        </Button>

        {/* Ellipsis for pages between current page and last page */}
        {totalPages > 3 && currentPage < totalPages - 3 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Last Page Button */}
        {totalPages > 3 && currentPage < totalPages - 2 && (
          <Button
            onClick={() => setCurrentPage(totalPages)}
            className={currentPage === totalPages ? "active" : ""}
          >
            {totalPages}
          </Button>
        )}

        {/* Next Button */}
        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>


      <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Make Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group controlId="Customer">
        <Form.Label>Customer</Form.Label>
        <Select
          onFocus={fetchAllCustomers}
          options={customers || []}
          placeholder="Select a Customer or type to search..."
          onChange={(selectedOption) => setSelectedCustomer(selectedOption)}
        />
        {/* Display validation error */}
        {formError && <p style={{ color: "red" }}>{formError}</p>}
      </Form.Group>
          <Table striped bordered hover className="mt-4">
             <thead>
                <tr>
                    <th>Item</th>
                    <th>Quantity</th>
                   <th>Unit Price</th>
                    <th>Action</th>
                 </tr>
            </thead>
            <tbody>
             {selectedItems.map((item, index) => (
              <tr key={index}>
                 <td>
          
            <Select
              value={item.item_id ? itemOptions.find(option => option.value === item.item_id) : null}
              onChange={(selectedOption) => handleItemSelect(selectedOption, index)}
              options={itemOptions}
              placeholder="Select or search item"
              isClearable
              isSearchable
              noOptionsMessage={() => "No items found"}
              styles={customStyles}
            />
          
        </td>
        <td>
          <Form.Control
            type="number"
            value={item.req_qty}
            onChange={(e) =>
              handleItemChange(index, "req_qty", e.target.value)
            }
            required
            min="1"
          />
        </td>
        <td>
          <Form.Control
            type="number"
            value={item.req_unitprice}
            onChange={(e) =>
              handleItemChange(index, "req_unitprice", e.target.value)
            }
            required
            min="100"
          />
        </td>
        <td>
          <Button
            variant="danger"
            onClick={() => handleRemoveItem(index)}
          >
            Remove
          </Button>
        </td>
      </tr>
    ))}
  </tbody>
</Table>
<Button variant="success" onClick={handleAddItem}>
              Add Item
            </Button>
            <Form.Group controlId="date">
              <Form.Label>Date:</Form.Label>
              <Form.Control
                type="date"
                value={orderdate}
                onChange={(e) => setOrderdate(e.target.value)}
                required
                min={today}
              />
            </Form.Group>
            <Form.Group controlId="comments" className="mb-4">
              <Form.Label>Comments</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              />
            </Form.Group>
            {/* Submit Button */}
            <br />
            {user && (
            <input type="hidden" name="user_order_by" value={user.id} />
          )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="success" disabled={!selectedItems.length} onClick={handleSubmit}>
            Save Order
          </Button>
        </Modal.Footer>
      </Modal>
 {/* PayNowModal */}
 <Modal show={showPayNowModal} onHide={() => setShowPayNowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Pay Now</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handlePayNowSubmit}>
          <Form.Group controlId="amountpaid">
            <Form.Label>Amount Paid</Form.Label>
            <Form.Control
              type="number"
              value={amountpaid}
              onChange={(e) => setAmountPaid(e.target.value)}
              required
              min="0"
            />
          </Form.Group>
          <Form.Group controlId="pay_mode">
            <Form.Label>Payment Method</Form.Label>
            <Form.Control
              as="select"
              value={pay_mode}
              onChange={(e) => setPayMode(e.target.value)}
              required
            >
             <option value="">Select Payment Method</option>
                {accounts.map((account) => (
                  <option key={account.id} value={account.account_name}>
                    {account.account_name}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="pay_date">
            <Form.Label>Payment Date</Form.Label>
            <Form.Control
              type="date"
              value={pay_date}
              onChange={(e) => setPayDate(e.target.value)}
              required
              min={today}
            />
          </Form.Group>

          <Button type="submit" variant="primary">
            Confirm Payment
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
    <Modal show={showDetailModal} onHide={handleCloseDetailModal} size="lg">
  <Modal.Header closeButton>
    <Modal.Title>Order Details</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {selectedOrder ? (
      <div>
       <div className="d-flex justify-content-between gap-2 mb-3"> <h5>Order Information</h5>  <Button variant="primary" onClick={() => handleDownload(selectedOrder)}>
              Download Order as PDF
            </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "15px",
          }}
        >
          <p>
            <strong>Order Number:</strong> {selectedOrder.order.order_id}
          </p>
          <p>
            <strong>Ordered By:</strong> {selectedOrder.order.name}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "15px",
          }}
        >
          <p>
            <strong>Customer Name:</strong> {selectedOrder.order.names}
          </p>
          <p>
            <strong>Date:</strong>{" "}
            {new Date(selectedOrder.order.order_date).toLocaleDateString()}
          </p>
        </div>
        <Button variant="primary"
          onClick={() =>
            handleShowPaymentHistoryModal(selectedOrder.order.order_id)
          }
        >
          View Payment History
        </Button>

        <h5>Details</h5>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {selectedOrder.details.map((detail) => (
              <tr key={detail.osi_id}>
                <td>{detail.item_name}</td>
                <td>{detail.total_quantity_per_item}</td>
                <td>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'RWF',
                  }).format(detail.unitprice)}
                </td>
                <td>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'RWF',
                  }).format(
                    detail.total_quantity_per_item * detail.unitprice
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Total and Paid Amounts Display */}
        <div style={{ marginTop: "15px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
              padding: "10px",
              backgroundColor: "#f8f9fa",
              borderRadius: "5px",
              border: "1px solid #ced4da",
            }}
          >
            <strong>
              Total Amount:{" "}
              <span style={{ color: "#343a40" }}>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'RWF',
                }).format(
                  selectedOrder.details.reduce(
                    (total, detail) =>
                      total + detail.total_quantity_per_item * detail.unitprice,
                    0
                  )
                )}
              </span>
            </strong>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
              padding: "10px",
              backgroundColor: "#e2e3e5",
              borderRadius: "5px",
              border: "1px solid #ced4da",
            }}
          >
            <strong>
              Paid Amount:{" "}
              <span style={{ color: "#28a745" }}>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'RWF',
                }).format(selectedOrder.paymentDetails.total_paid)}
              </span>
            </strong>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              backgroundColor: "#f5c6cb",
              borderRadius: "5px",
              border: "1px solid #ced4da",
            }}
          >
            <strong>
              Remaining Amount:{" "}
              <span style={{ color: "#dc3545" }}>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'RWF',
                }).format(
                  selectedOrder.details.reduce(
                    (total, detail) =>
                      total + detail.total_quantity_per_item * detail.unitprice,
                    0
                  ) - selectedOrder.paymentDetails.total_paid
                )}
              </span>
            </strong>
          </div>
        </div>
        
        <Button
          variant="success"
          className="mt-4"
          disabled={
            selectedOrder.details.reduce(
              (total, detail) =>
                total + detail.total_quantity_per_item * detail.unitprice,
              0
            ) - selectedOrder.paymentDetails.total_paid
          ===0}
          onClick={() =>
            handleShowPaymentModal(selectedOrder.order.order_id)
          }
        >
          Record Payment
        </Button>
      </div>
    ) : (
      <p>Loading details...</p>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseDetailModal}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

      <Modal
        show={showPaymentHistoryModal}
        onHide={handleClosePaymentHistoryModal}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {paymentHistory.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Amount(Frw)</th>
                  <th>Payment Method</th>
                  
                  <th>Refence</th><th>Payment Date</th>
                </tr>
              </thead>
              <tbody>
                {paymentHistory.map((payment) => (
                  <tr key={payment.pay_id}>
                    <td>{payment.amount_paid}</td>
                    <td>{payment.payment_mode}</td>
                    <td>{payment.reference}</td>
                    <td>
                      {new Date(payment.record_date).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No payment history available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePaymentHistoryModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPaymentModal} onHide={handleClosePaymentModal}>
        <Modal.Header closeButton>
          <Modal.Title>Record Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="paymentMethod">
              <Form.Label>Payment Method:</Form.Label>
              <Form.Control
                as="select"
                value={paymentMethod}
                onChange={handlePaymentMethodChange}
                required
              >
                <option value="">Select Payment Method</option>
                {accounts.map((account) => (
                  <option key={account.id} value={account.account_name}>
                    {account.account_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="paymentAmount">
              <Form.Label>Amount:</Form.Label>
              <Form.Control
                type="number"
                value={paymentAmount}
                onChange={handlePaymentAmountChange}
                placeholder="Enter amount"
                min="0"
                step="0.01"
                required
              />
            </Form.Group>
            <Button variant="primary" className="mt-4" onClick={handleRecordPayment}>
              Record Payment
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClosePaymentModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StocksManagement;
