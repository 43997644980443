import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="container-fluid pt-4 px-4">
      <div className="bg-light rounded-top p-4">
        <div className="row">
          <div className="col-12 col-sm-6 text-center text-sm-start">
            Copyright &copy; <a href="#">Olivier Company Ltd.</a>, {currentYear}{" "}
            All Rights Reserved.
          </div>
          <div className="col-12 col-sm-6 text-center text-sm-end">
            Designed By <a href="https://tol.com">Tol Developer</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
