import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Form } from "react-bootstrap";
import axios from "../axiosInstance";
import Swal from "sweetalert2";
import { BsArrowDownUp } from "react-icons/bs";
import { FaPen, FaTrashAlt } from "react-icons/fa";

function RolesManagement() {
  // State to control modals
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [roles, setRoles] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [rolesPerPage] = useState(10);
  const [sortedField, setSortedField] = useState(null);
  const [editRole, setEditRole] = useState(null);
  useEffect(() => {
    const fetchRoles = async () => {
      const RoleRes = await axios.get("/api/roles");
      setRoles(RoleRes.data);
    };
    fetchRoles();
  }, []);
  const handleSort = (field) => {
    setSortedField(field);
    const sortedData = [...roles].sort((a, b) => {
      if (a[field] < b[field]) return -1;
      if (a[field] > b[field]) return 1;
      return 0;
    });
    setRoles(sortedData);
  };
  // Toggle modals
  const handleAddModal = () => setShowAddModal(!showAddModal);
  const handleUpdateModal = (role) => {
    setSelectedRole(role);
    setShowUpdateModal(true);
  };
 
  // Handle form submissions (Add, Update, Delete)
  const handleAddRole =  async () =>{

    try {
      const response = await axios.post("/api/add-role", {
        role_name: roleName,
      });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
      handleAddModal();
      fetchRoles();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error adding Role!",
      });
    }
     // Close modal
  };
  const fetchRoles = async () => {
    try {
      const response = await axios.get("/api/roles");
      setRoles(response.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };
  const handleUpdateRole = async (role_id)  => {
    try {
      const response = await axios.post("/api/update-role", {
        role_name: editRole.role_name,
        role_id: editRole.role_id,
      });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
      setShowUpdateModal(false);
      fetchRoles();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error updating Role!",
      });
    }
  };
  const handleDeleteRole = async (role_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Role!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/api/delete-role/${role_id}`);
          Swal.fire({
            icon: "success",
            title: "Deleted",
            text: "Role has been deleted.",
          });
          fetchRoles();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "There was an error deleting the Role!",
          });
        }
      }
    });
  };
  const filteredRoles = roles.filter((avrole) => {
    const name = avrole.role_name  ? avrole.role_name.toLowerCase() : '';
  
    return (
      name.includes(searchTerm.toLowerCase())
    );
  });
  

  const indexOfLastRole = currentPage * rolesPerPage;
  const indexOfFirstRole = indexOfLastRole - rolesPerPage;
  const currentRoles = filteredRoles.slice(
    indexOfFirstRole,
    indexOfLastRole
  );

  const totalPages = Math.ceil(filteredRoles.length / rolesPerPage);

  return (
    <div className="container p-3">
      <div className="mt-1">
        <div className="d-flex justify-content-between align-Roles-center mb-3 p-2">
          <h2>Roles List</h2>
          <Button variant="primary" className="mb-4" onClick={handleAddModal}>
            Add New Role
          </Button>
        </div>
      </div><div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" style={{height: '50px'}}>
                      <i className="fas fa-search"></i> 
                    </span>
                  </div>
      <Form.Control
        type="text"
        placeholder="Search .........."
        className="mb-3"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      /></div></div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => handleSort("role_name")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Role Name</b>
                <BsArrowDownUp style={{ color: '#33a8ff', cursor: 'pointer' }} />
              </div>
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
             
        {currentRoles.map((avrole) => (
            <tr key={avrole.role_id}>
              <td>{avrole.role_name}</td>
              <td><Button
                  variant="warning"
                  onClick={() => {
                    setEditRole(avrole);
                    setShowUpdateModal(true);
                  }}
                >
                  <FaPen style={{color: "#fff"}}/>
                </Button>  <Button
                    variant="danger"
                    className="ms-2"
                    onClick={() => handleDeleteRole(avrole.role_id)}
                  >
                    <FaTrashAlt />
                  </Button>
                </td>
          </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex gap-2 justify-content-end mt-3">
        {/* Previous Button */}
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>

        {/* First Page Button */}
        {totalPages > 3 && currentPage > 3 && (
          <Button
            onClick={() => setCurrentPage(1)}
            className={currentPage === 1 ? "active" : ""}
          >
            1
          </Button>
        )}

        {/* Ellipsis for pages between first page and current page */}
        {totalPages > 3 && currentPage > 4 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Current Page Button */}
        <Button onClick={() => setCurrentPage(currentPage)} className="active">
          {currentPage}
        </Button>

        {/* Ellipsis for pages between current page and last page */}
        {totalPages > 3 && currentPage < totalPages - 3 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Last Page Button */}
        {totalPages > 3 && currentPage < totalPages - 2 && (
          <Button
            onClick={() => setCurrentPage(totalPages)}
            className={currentPage === totalPages ? "active" : ""}
          >
            {totalPages}
          </Button>
        )}

        {/* Next Button */}
        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
      {/* Add Role Modal */}
      <Modal show={showAddModal} onHide={handleAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form >
            <Form.Group controlId="roleName" className="mt-2">
              <Form.Label>Role Name</Form.Label>
              <Form.Control type="text" placeholder="Enter role name" required value={roleName} onChange={(e) => setRoleName(e.target.value)} />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3" onClick={handleAddRole}>
              Save Role
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Update Role Modal */}
      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form >
            <Form.Group controlId="updateRoleName" className="mt-2">
              <Form.Label>Role Name</Form.Label>
              <Form.Control
                type="text"
                value={editRole?.role_name || ""}
                onChange={(e) =>
                  setEditRole({
                    ...editRole,
                    role_name: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            <Form.Group controlId="updateRoleName" className="mt-2">
             
              <Form.Control
                type="hidden"
                value={editRole?.role_id || ""}
                onChange={(e) =>
                  setEditRole({
                    ...editRole,
                    role_id: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3" onClick={handleUpdateRole}>
              Update Role
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RolesManagement;
