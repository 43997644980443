import React, { useEffect, useState } from "react";
import axios from "../axiosInstance";
import { Table, Button, Form, Modal, NavItem } from "react-bootstrap";
import {
  FaRegPlusSquare,
  FaUserCircle,
  FaChevronCircleDown,
  FaTrashAlt,
  FaPen,
  FaFileExcel,
} from "react-icons/fa";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { VscCloudUpload, VscPulse } from "react-icons/vsc";

const ItemsManagement = () => {
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [items, setItems] = useState([]);
  const [newItemsName, setnewItemsName] = useState("");
  const [newItemId, setNewItemId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [editItem, seteditItem] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchItems = async () => {
      const itemsRes = await axios.get("/api/items");
      setItems(itemsRes.data);
    };
    fetchItems();
  }, []);
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);
  useEffect(() => {
    if (showModal) {
      fetchMaxItemId();
    }
  }, [showModal]);
  const fetchItems = async () => {
    try {
      const response = await axios.get("/api/items");
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };
  const fetchMaxItemId = async () => {
    try {
      const response = await axios.get("/api/items/max-id");
      const currentMaxItemId = response.data.maxItemId;
      setNewItemId(currentMaxItemId + 1);
    } catch (error) {
      console.error("Error fetching max item ID:", error);
    }
  };

  const handleAddItem = async () => {
    try {
      const response = await axios.post("/api/items", {
        item_name: newItemsName,
        item_id: newItemId,
      });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
      setShowModal(false);
      fetchItems();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error adding the item!",
      });
    }
  };

  const handleDeleteItem = async (item_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this  Item Permanently!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/api/items/${item_id}`);
          Swal.fire({
            icon: "success",
            title: "Deleted",
            text: "Item has been deleted.",
          });
          fetchItems();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "There was an error deleting the Items!",
          });
        }
      }
    });
  };

  const handleUpdateLocation = async () => {
    try {
      const response = await axios.put(`/api/items/${editItem.item_id}`, {
        item_name: editItem.item_name,
        min_stock_qty: editItem.min_stock_qty,
        deleted: editItem.deleted,
      });

      Swal.fire({
        icon: "success",
        title: "Updated",
        text: response.data.message,
      });

      setEditModal(false);
      fetchItems();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error updating the Item!",
      });
    }
  };

  const handleSort = (field) => {
    setSortedField(field);
    const sortedData = [...items].sort((a, b) => {
      if (a[field] < b[field]) return -1;
      if (a[field] > b[field]) return 1;
      return 0;
    });
    setItems(sortedData);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handleUpload = async () => {
    if (!file) {
      Swal.fire({
        icon: "warning",
        title: "No file selected",
        text: "Please select an Excel file to upload.",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("/api/items/import", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
      fetchItems();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error uploading the file!",
      });
    }
  };
  const filteredItems = items.filter((item) =>
    item.item_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate pagination based on the filtered items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  return (
    <div className="container mt-5 p-6">
      <br />
      <div className="d-flex justify-content-between gap-2 align-items-center mb-3">
        <Button
          variant="primary"
          className="ms-2"
          onClick={() => setShowModal(true)}
        >
          <div className="d-flex justify-content-between gap-2 align-items-center mb-1">
            <FaRegPlusSquare />
            <b>Add New Item</b>
          </div>
        </Button>
        <div>
          <Form className="d-flex gap-2">
            <Form.Group className="mb-3">
              <Form.Label>
                <b>Upload Excel File</b>
              </Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
            <Button
              className="mt-4 align-items-center"
              variant="primary"
              onClick={handleUpload}
              style={{
                height: "45px",
                width: "40px",
                padding: 0,
              }}
            >
              {"  "} <VscCloudUpload />
            </Button>
          </Form>
        </div>
      </div>
      {/* Search Input */}
      <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" style={{height: '50px'}}>
                      <i className="fas fa-search"></i> 
                    </span>
                  </div>
      <Form.Control
        type="text"
        placeholder="Search .........."
        className="mb-3"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      </div>
      </div>

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>ID</th>
            <th onClick={() => handleSort("item_name")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Name</b>
                <FaChevronCircleDown />
              </div>
            </th>
            <th onClick={() => handleSort("min_stock_qty")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Min Quantity</b>
                <FaChevronCircleDown />
              </div>
            </th>
            <th onClick={() => handleSort("deleted")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Status</b>
                <FaChevronCircleDown />
              </div>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item) => (
            <tr key={item.item_id}>
              <td>{item.item_id}</td>
              <td>{item.item_name}</td>
              <td>{item.min_stock_qty}</td>
              <td>
                {item.deleted === 1 ? (
                  <span style={{ color: "red" }}>Inactive</span>
                ) : (
                  <span style={{ color: "green" }}>Active</span>
                )}
              </td>
              <td>
                {/* Edit button always visible */}
                <Button
                  variant="warning"
                  onClick={() => {
                    seteditItem(item);
                    setEditModal(true);
                  }}
                >
                  <FaPen />
                </Button>

                {/* Delete button only visible if not deleted */}
                {item.deleted === 0 && (
                  <Button
                    variant="danger"
                    className="ms-2"
                    onClick={() => handleDeleteItem(item.item_id)}
                  >
                    <FaTrashAlt />
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination */}
      <div className="d-flex gap-2 justify-content-end mt-3">
        {/* Previous Button */}
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>

        {/* First Page Button */}
        {totalPages > 3 && currentPage > 3 && (
          <Button
            onClick={() => setCurrentPage(1)}
            className={currentPage === 1 ? "active" : ""}
          >
            1
          </Button>
        )}

        {/* Ellipsis for pages between first page and current page */}
        {totalPages > 3 && currentPage > 4 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Current Page Button */}
        <Button onClick={() => setCurrentPage(currentPage)} className="active">
          {currentPage}
        </Button>

        {/* Ellipsis for pages between current page and last page */}
        {totalPages > 3 && currentPage < totalPages - 3 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Last Page Button */}
        {totalPages > 3 && currentPage < totalPages - 2 && (
          <Button
            onClick={() => setCurrentPage(totalPages)}
            className={currentPage === totalPages ? "active" : ""}
          >
            {totalPages}
          </Button>
        )}

        {/* Next Button */}
        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>

      {/* Add Item Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Item ID</Form.Label>
              <Form.Control
                type="text"
                readOnly
                value={newItemId || ""}
                placeholder="Loading item ID..."
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Item Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Item name"
                value={newItemsName}
                onChange={(e) => setnewItemsName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddItem}>
            Add Item
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Item Modal */}
      <Modal show={editModal} onHide={() => setEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Item </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Item Name</Form.Label>
              <Form.Control
                type="text"
                value={editItem?.item_name || ""}
                onChange={(e) =>
                  seteditItem({
                    ...editItem,
                    item_name: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Min Quantity</Form.Label>
              <Form.Control
                type="text"
                value={editItem?.min_stock_qty || ""}
                onChange={(e) =>
                  seteditItem({
                    ...editItem,
                    min_stock_qty: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Select Status</Form.Label>
              <Form.Control
                as="select"
                value={editItem?.deleted || ""}
                onChange={(e) =>
                  seteditItem({
                    ...editItem,
                    deleted: parseInt(e.target.value),
                  })
                }
              >
                <option value="">Select Status</option>
                <option value="0">Active</option>
                <option value="1">Inactive</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateLocation}>
            Update Stock
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ItemsManagement;
