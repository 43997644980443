import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const AddSupplierModal = ({ show, handleClose, handleSave }) => {
  const [newSupplierName, setNewSupplierName] = useState("");
  const [newSupplierNumber, setNewSupplierNumber] = useState("");

  const handleNameChange = (e) => {
    setNewSupplierName(e.target.value);
  };

  const handleSaveClick = () => {
    handleSave(newSupplierName);
    setNewSupplierName("");
    setNewSupplierNumber("");
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Supplier</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="newSupplierName">
          <Form.Label>Supplier Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter supplier name"
            value={newSupplierName}
            onChange={handleNameChange}
          />
        </Form.Group>
        <Form.Group controlId="newSupplierNumber">
          <Form.Label>Supplier Phone Number</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter supplier Phone Number"
            value={newSupplierNumber}
            max="10"
            min="10"
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSaveClick}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSupplierModal;
