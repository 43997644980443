import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import LoginPage from "./components/LoginPage";
import NotFound from "./components/NotFound";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Purchaseitems from "./RunPages/PurchaseItems";
import ManuMAnagement from "./RunPages/ManuMAnagement";
import StocksManagement from "./RunPages/Stocks";
import UsersManagement from "./RunPages/Users";
import Items from "./RunPages/Items";
import Itemstock from "./RunPages/Itemstock";
import Suppliers from "./RunPages/Suppliers";
import Customers from "./RunPages/Customers";
import Deliverpage from "./RunPages/Deliverpage";
import OrderManagement from "./RunPages/Orders";
import Roles from "./RunPages/Roles";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/Dashbord-admin" element={<App />} />
        <Route path="*" element={<NotFound />} />{" "}
        <Route path="/Purchase_Items" element={<Purchaseitems />} />
        <Route path="/stocks" element={<StocksManagement />} />
        <Route path="/manage_order" element={<OrderManagement />} />
        <Route path="/items" element={<Items />} />
        <Route path="/items-in-stock" element={<Itemstock />} />
        <Route path="/Users" element={<UsersManagement />} />
        <Route path="/roles" element={<Roles />} />
        <Route path="/delivery" element={<Deliverpage />} />
        <Route path="/suppliers" element={<Suppliers />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/Menu-Assign-Management" element={<ManuMAnagement />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
