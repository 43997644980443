import React, { useEffect, useState } from "react";
import axios from "../axiosInstance";
import { Table, Button, Form, Modal } from "react-bootstrap";
import {
  FaRegPlusSquare,
  FaTrashAlt,
  FaPen,
  FaToolbox,
} from "react-icons/fa";
import Swal from "sweetalert2";
import { VscCloudUpload, VscPulse } from "react-icons/vsc";
import { BsArrowDownUp } from "react-icons/bs";
const StocksManagement = () => {
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [locations, setLocations] = useState([]);
  const [managers, setManagers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [editLocation, setEditLocation] = useState(null);
  const [file, setFile] = useState(null);
  const [stockId, setstockId] = useState("");
  const [itemId, setItemId] = useState("");
  const [Qty, setQty] = useState("");
  const [cost, setCost] = useState("");
  const [stocks, setStock] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchManagers = async () => {
      const managersRes = await axios.get("/api/users");
      setManagers(managersRes.data);
    };

    const fetchLocations = async () => {
      const locationsRes = await axios.get("/api/stockview");
      setLocations(locationsRes.data);
    };

    fetchManagers();
    fetchLocations();
  }, []);
  useEffect(() => {
    axios
    .get("/api/stocks")
    .then((response) => setStock(response.data));
    axios
    .get("/api/items")
    .then((response) => setItems(response.data));
  }, []);
  const fetchLocations = async () => {
    try {
      const response = await axios.get("/api/stockview");
      setLocations(response.data);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const handleAddStockItem = async () => {
    try {
      const response = await axios.post("/api/add-item-in-stock", {
        item_id: itemId,
        stockId: stockId,
        req_unityPrice: cost,
        newqty: Qty,
      });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
      setShowModal(false);
      fetchLocations();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error adding the Item in stock!",
      });
    }
  };

  const handleDeleteStock = async (stock_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this stock Permanently!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/api/stocks/${stock_id}`);
          Swal.fire({
            icon: "success",
            title: "Deleted",
            text: "Stock has been deleted.",
          });
          fetchLocations();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "There was an error deleting the stock location!",
          });
        }
      }
    });
  };

  const handleUpdateLocation = async () => {
    try {
      const response = await axios.put(`/api/stocks/${editLocation.stock_id}`, {
        stock_name: editLocation.stock_name,
        deleted: editLocation.deleted,
      });

      Swal.fire({
        icon: "success",
        title: "Updated",
        text: response.data.message,
      });

      setEditModal(false);
      fetchLocations();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error updating the stock!",
      });
    }
  };

  const handleSort = (field) => {
    setSortedField(field);
    const sortedData = [...locations].sort((a, b) => {
      if (a[field] < b[field]) return -1;
      if (a[field] > b[field]) return 1;
      return 0;
    });
    setLocations(sortedData);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handleUpload = async () => {
    if (!file) {
      Swal.fire({
        icon: "warning",
        title: "No file selected",
        text: "Please select an Excel file to upload.",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("/api/add-item-in-stock/import", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
      fetchLocations();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error uploading the file!",
      });
    }
  };
  const filteredLocations = locations.filter((location) => {
    const name = location.name ? location.name.toLowerCase() : '';
    const stockName = location.stock_name ? location.stock_name.toLowerCase() : '';
    const itemName = location.item_name ? location.item_name.toLowerCase() : '';
    const recordDate = location.record_date ? location.record_date.toLowerCase() : '';
  
    return (
      name.includes(searchTerm.toLowerCase()) ||
      stockName.includes(searchTerm.toLowerCase()) ||
      itemName.includes(searchTerm.toLowerCase()) ||
      recordDate.includes(searchTerm.toLowerCase())
    );
  });
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentLocations = filteredLocations.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredLocations.length / itemsPerPage);

  return (
    <div className="container mt-5 p-6">
      <br />
      <div className="d-flex justify-content-between gap-2 align-items-center mb-3">
        <Button
          variant="primary"
          className="ms-2"
          onClick={() => setShowModal(true)}
        >
          <div className="d-flex justify-content-between gap-2 align-items-center mb-1">
            <FaRegPlusSquare />
            <b>Add Stock item
            </b>
            <FaToolbox />
          </div>
        </Button>
        <div>
          <Form className="d-flex gap-2">
            <Form.Group className="mb-3">
              <Form.Label>
                <b>Upload Excel File</b>
              </Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
            <Button
              className="mt-4 align-items-center"
              variant="primary"
              onClick={handleUpload}
              style={{
                height: "45px",
                width: "40px",
                padding: 0,
              }}
            >
              {"  "} <VscCloudUpload />
            </Button>
          </Form>
        </div>
      </div>
      {/* Search Input */}
      <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" style={{height: '50px'}}>
                      <i className="fas fa-search"></i> 
                    </span>
                  </div>
      <Form.Control
        type="text"
        placeholder="Search .........."
        className="mb-3"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      </div>
      </div>

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th onClick={() => handleSort("name")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Stock Keeper</b>
                <BsArrowDownUp style={{ color: '#33a8ff', cursor: 'pointer' }} />
              </div>
            </th>
            <th onClick={() => handleSort("stock_name")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Stock Name</b>
                <BsArrowDownUp style={{ color: '#33a8ff', cursor: 'pointer' }} />
              </div>
            </th>
            <th onClick={() => handleSort("record_date")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Recorded Date</b>
                <BsArrowDownUp style={{ color: '#33a8ff', cursor: 'pointer' }} />
              </div>
            </th>
            <th onClick={() => handleSort("item_name")}>
              <div className="d-flex justify-content-between gap-2">
                <b>item Name</b>
                <BsArrowDownUp style={{ color: '#33a8ff', cursor: 'pointer' }} />
              </div>
            </th>
            <th onClick={() => handleSort("Qty")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Quantity</b>
                <BsArrowDownUp style={{ color: '#33a8ff', cursor: 'pointer' }} />
              </div>
            </th>
            <th onClick={() => handleSort("cost")}>
              <div className="d-flex justify-content-between gap-2">
                <b>Cost</b>
                <BsArrowDownUp style={{ color: '#33a8ff', cursor: 'pointer' }} />
              </div>
            </th>
           
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
           
          {currentLocations.map((location) => (
            <tr key={location.stock_no}><td>{location.name}</td>
              <td>{location.stock_name}</td>
              
              <td>{new Date(location.record_date).toLocaleDateString()}</td>
              <td>{location.item_name}</td>
              <td>{location.Qty}</td>
              <td>{location.cost}</td>
             
              <td>
                {/* Edit button always visible */}
                <Button
                  variant="warning"
                  onClick={() => {
                    setEditLocation(location);
                    setEditModal(true);
                  }}
                >
                  <FaPen style={{color: "#fff"}}/>
                </Button>

                {/* Delete button only visible if not deleted */}
                {location.deleted === 0 && (
                  <Button
                    variant="danger"
                    className="ms-2"
                    onClick={() => handleDeleteStock(location.stock_id)}
                  >
                    <FaTrashAlt />
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination */}
      <div className="d-flex gap-2 justify-content-end mt-3">
        {/* Previous Button */}
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>

        {/* First Page Button */}
        {totalPages > 3 && currentPage > 3 && (
          <Button
            onClick={() => setCurrentPage(1)}
            className={currentPage === 1 ? "active" : ""}
          >
            1
          </Button>
        )}

        {/* Ellipsis for pages between first page and current page */}
        {totalPages > 3 && currentPage > 4 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Current Page Button */}
        <Button onClick={() => setCurrentPage(currentPage)} className="active">
          {currentPage}
        </Button>

        {/* Ellipsis for pages between current page and last page */}
        {totalPages > 3 && currentPage < totalPages - 3 && (
          <span className="pagination-ellipsis">...</span>
        )}

        {/* Last Page Button */}
        {totalPages > 3 && currentPage < totalPages - 2 && (
          <Button
            onClick={() => setCurrentPage(totalPages)}
            className={currentPage === totalPages ? "active" : ""}
          >
            {totalPages}
          </Button>
        )}

        {/* Next Button */}
        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>

      {/* Add Stock Modal  */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Stock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group controlId="S">
            <Form.Label>Stock</Form.Label>
            <Form.Control
              as="select"
              value={stockId}
              onChange={(e) => setstockId(e.target.value)}
              required
            >
                <option value="">Select Stock</option>
                {stocks.map((stock) => (
                  <option key={stock.stock_id} value={stock.stock_id}>
                    {stock.stock_name}
                  </option>
                ))}
            </Form.Control>
            </Form.Group>
            <Form.Group controllId="I">
            <Form.Label>Item Name</Form.Label>
            <Form.Control
              as="select"
              value={itemId}
              onChange={(e) => setItemId(e.target.value)}
              required
            >
                <option value="">Select Item</option>
                {items.map((item) => (
                  <option key={item.item_id} value={item.item_id}>
                    {item.item_name}
                  </option>
                ))}
            </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Enter Quantity</Form.Label>
              <Form.Control
                type="number" value={Qty} onChange={(e) => setQty(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Enter Cost Price</Form.Label>
              <Form.Control
                type="number" value={cost} onChange={(e) => setCost(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddStockItem}>
            Add Stock
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Stock Modal */}
      <Modal show={editModal} onHide={() => setEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Stock </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Stock Name</Form.Label>
              <Form.Control
                type="text"
                value={editLocation?.stock_name || ""}
                onChange={(e) =>
                  setEditLocation({
                    ...editLocation,
                    stock_name: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Select Status</Form.Label>
              <Form.Control
                as="select"
                value={editLocation?.deleted || ""}
                onChange={(e) =>
                  setEditLocation({
                    ...editLocation,
                    deleted: parseInt(e.target.value),
                  })
                }
              >
                <option value="">Select Status</option>
                <option value="0">Active</option>
                <option value="1">Inactive</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateLocation}>
            Update Stock
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StocksManagement;
